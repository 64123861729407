
/* Navbar */

.logo {
    max-width: 200px;
  }

.menu-item {
  background-color: #fff!important;
  z-index: 900;
}


.navbar {
  align-items: center;
  background: #fff;
  font-size: 15px;
  box-shadow: 0 5px 20px rgba(0,0,0,0.2);
  display: flex;
  flex-direction: row;
  font-family: sans-serif;
  padding: 10px 50px;
}

.push-left {
  margin-left: auto;
}


/* Menu */
.hamburger {
  background: transparent;
  border: none;
  cursor: pointer;
  display: none;
  outline: none;
  height: 30px;
  position: relative;
  width: 30px;
  z-index: 1000;
  @media screen and (max-width: 768px) {
  
  
    display: inline-block;
  }
  
  &-line {
    background: #272727;
    height: 3px;
    position: absolute;
    left: 0;
    transition: all .2s ease-out; 
    width: 100%;
    
    .hamburger:hover & {
      background: #777;
    }
    
    &-top {
      top: 3px;
    }
    
    .menu-active &-top {
      top: 50%;
      transform: rotate(45deg) translatey(-50%);
    }
    
    &-middle {
      top: 50%;
      transform: translatey(-50%);
    }
    
    .menu-active &-middle {
      left: 50%;
      opacity: 0;
      width: 0;
    }

    &-bottom {
      bottom: 3px;
    }

    .menu-active &-bottom {
      bottom: 50%;
      transform: rotate(-45deg) translatey(50%);
    }
  }
}

.nav-menu {
  display: flex;
  list-style: none;
  margin: 0;
  z-index: 900;
  padding: 0;
  transition: all .25s ease-in; 

  .logo{
    width: 250px!important;
  }
  
  @media screen and (max-width: 768px) {

    .nav-menu {
      margin-top: 9rem;
    }
    background: #fff;
    flex-direction: column;
    margin-top: 12rem;
    justify-content: center;
    opacity: 0;
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    transform: translatey(-100%);
    text-align: center;
    
    .menu-active & {
      transform: translatey(0%);  
      opacity: 1;
    }
  }
  
  .menu-item a {
    color: #444444;
    display: block;
    line-height: 30px;
    margin: 0px 10px;
    text-decoration: none;
    text-transform: uppercase;
    
    &:hover {
      color: lighten(#444444, 20);
      text-decoration: underline;
    }

    @media screen and (max-width: 768px) {
       
      font-size: 20px;
      margin: 15px;
    }
  }
  
  
}

.nav-menu .menu-item a{
  font-size: 20px;
  padding-top: 20px;
  padding-bottom: 20px;

 
}

.nav-menu .menu-item a:hover{
  color: #003193;
  text-decoration: none;
}

.active{
  color: #003193;
}
.sub-nav {
    border: 1px solid #ccc;
    display: none;
    position: absolute;
    background-color: #fff;
    padding: 5px 5px;
    list-style: none; 
    width: 230px;   
    @media screen and (max-width: 768px) {
      position: relative;
      width: 100%;
      display: none;
      background-color: rgba(0, 0, 0, 0.20);
      box-sizing: border-box;
    }
}

 .nav__link {
   &:hover + .sub-nav {
        display:block;

 }
}

.sub-nav {
  &:hover {
    display:block;
  }
}