


/*Testimonies*/


.client {
  width: 100%;
	height: 100%;

}

.carousel-icon {
    
    i {
        font-size: 5rem;
        color: #fff;
    }

}
.carousel-item {

    i {
        font-size: 1.6rem;
         color: #fff;
    }
}

.t-card {
    padding: 1.8125rem 1.125rem;
    background-color: rgba(0, 0, 0, 0.577);
    border-radius: 1.25rem;
    color: #fff;
    height: auto;
}

.arrow-down {
    width: 0;
    height: 0;
    border-left: 1.5625rem solid transparent;
    border-right: 1.5625rem solid transparent;
    border-top: 1.25rem solid rgba(0, 0, 0, 0.53);
    // margin-left: 4.6875rem
}



/* Testimonials */
#testimonials {
    color: white;
    background-image: url(../img/image-2.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    position: relative;
  }
  
  @media (max-width: 991px) {

    .cc-content-box, .cc-content-box-1{
      text-align: center!important;
    }
   
    .box{
      text-align: center;
    }
    #testimonials {
      background-image: url(../img/image-2.jpg);
    }
  }
  
  .cc-testimonials-content {
    position: relative;
    z-index: 100;
  }
  
  .cc-bg-overlay {
    width: 100%;
    height: 100%;
    background: rgba(20, 70, 80, 0.2);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
  }
  
  .cc-testimonials-carousel {
    max-width: 1050px;
    margin: 0 auto;
  }
  
  .cc-testimonial-item {
    max-width: 900px;
    margin-left: 20px;
    margin-right: 20px;
  }
  
  .cc-testimonial-item img {
    border-radius: 50%;
    margin-bottom: 35px;
  }
  
  .cc-testimonial-item figcaption {
    text-align: right;
    font-style: italic;
    font-size: 1.1rem;
  }
  
  .cc-testimonial-item blockquote {
    text-align: right;
    font-style: italic;
    font-size: 0.7rem;
  }
  