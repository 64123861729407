
/* Hero */

#hero {
    color: white;
    background-image: url(../img/hero.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    background-attachment: fixed;
  }