
@import url("https://fonts.googleapis.com/css?family=Raleway:100,300,400,500,700,900");

html{
  scroll-behavior: smooth;
}

body {
	font-family: "Raleway", sans-serif;
	font-size: 1.2em;
  color: #707070;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
a {
  transition: all 0.3s ease;
  color:rgb(32, 79, 144);
  text-decoration: none;
}

a:hover,
a:focus {
  text-decoration: underline;
  color: #38B;
}

a:focus {
  outline: none;
}

.btn {
  padding: 8px 32px;
}

.btn:hover {
  background-color: #ced4da;
}

blockquote {
  font-size: 0.86em;
  line-height: 1.8em;
}

.cc-section-pad-top {
  padding-top: 80px;
  padding-bottom: 40px;
}

.cc-content-box {
  padding-top: 20px;
  padding-bottom: 40px;
}

.cc-content-box-1 {
  padding-top: 20px;
  padding-bottom: 40px;
  margin-top: 6rem;
}

.btn-primary{
  color: #fff;
  background-color: #8F8E46;
  font-size: 1.5rem;
  border: none;
  font-weight: 300;
}


.cc-text-primary {
  color: #204F90;
}

.cc-font-big {
  font-size: 1.25rem;
}

.cc-btn-primary {
  color: white;
  background-color: #369;
  padding: 14px 30px;
}

.cc-section-title {
  font-size: 3rem;
  font-weight: normal;
  font-family: 'Playfair Display', serif;
}


/*about*/
.box {
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  padding: 10px;
  width: 100%;
}



  


