/*contact*/

  .img-service{
    top: 50%!important;
    margin-top: 1.5rem;
    height: 90px;
    margin-bottom: 1rem;
  }
  


  .blue-text{
    color: #003193;
  }

  .container-map{
    background: linear-gradient( rgba(0, 0, 0, 0.345), rgba(0, 0, 0, 0.31) ), url("../img/map.png");
    -webkit-background-size: cover;
    background-size: cover;
    background-attachment: fixed;
    background-position: center center;
    margin-top: -2.8rem!important;
    padding-bottom: 4rem;
}

.location{
  margin-top: 10rem!important;
}


.information-container {
  background: #000000;
  background: -webkit-linear-gradient(45deg, #434343, #000000);
  background: linear-gradient(45deg, #434343, #000000);

  background-size: cover;
  padding: 60px 0;
}

.inner-container {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  padding: 0 20px;
  overflow: hidden;
}

.section-title {
  text-align: center;
  color: #ddd;
  text-transform: uppercase;
  font-size: 30px;
}

.border {
  width: 160px;
  height: 2px;
  margin: 40px auto;
}

.service-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.service-box {
  max-width: 33.33%;
  padding: 10px;
  text-align: center;
  color: #ddd;
  cursor: pointer;
}

.service-icon {
  display: inline-block;
  width: 70px;
  text-align: center!important;
  height: 70px;
  border: 3px solid #cb2d3e;
  border-radius: 50%;
  margin-bottom: 30px;
  margin-top: 16px;
}

.service-icon i {
  line-height: 70px;
  transform: rotate(-45deg);
  font-size: 26px;
}

.service-box:hover .service-icon {
  border: solid 10px #E2C136;
  color: #ddd;
}

.service-title {
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 10px;
.description {
  font-size: 14px;
}


@media screen and (max-width:960px) {
  .service-box {
    max-width: 45%;
  }
}

@media screen and (max-width:768px) {
  .service-box {
    max-width: 50%;
  }
}

@media screen and (max-width:480px) {
  .service-box {
    max-width: 100%;
  }
}
}



